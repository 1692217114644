// npm install --save normalize.css reset css
@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
$active--Color: #31a24c;
:root {
  --primary: #fe2c55;
  --backGround-App: #6e00ff;
  --backGround-App2: #6e01fd;
  --text-color: #2b3036;
  --text-chat: #333;
  --Icon-Color: #fff;
  --backGround--page: #f3f6ff;
  --backGround--page2--white: #fff;

  --backgroundTransparent: transparent;
  // --backGround--page2--white: transparent;
  // --backGround--page: transparent;

  //defaultLayout
  --default-layout-Nav-height: 60px;
  --default-layout-Nav-height--mobile: 50px;
  --default-layout--mobile--bottom: 8px;
}
@font-face {
  font-family: fontMain;
  src: url("../../assets/font/roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: fontUtm;
  src: url("../../assets/font/fontUTM/UTM\ Swiss\ Condensed.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  background: url("../../assets/images/login.jpg") top center/ cover no-repeat;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100% !important;
  // font-family: fontUtm;
  font-family: fontMain;
  font-size: 1.6rem;
  // letter-spacing: 1px;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  scrollbar-width: none;
  @include mobile {
    // position: fixed;
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // background: url("../../assets/images/login.jpg") top center/ cover no-repeat;

    // height: 100vh;
  }
}
.backgroundTransparentApp {
  background-color: transparent !important;
  opacity: 0;
}
.backgroundTransparentApp:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1;
}
.backgroundTransparent {
  background-color: transparent !important;

  // border: 2px solid #838383;
}
.backgroundTransparentBlackBorder {
  border: 1px solid #838383;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.backgroundTransparent:hover {
  border: 1px solid #838383;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.backgroundTransparentNoBorder {
  background-color: transparent !important;
}
.backgroundTransparentNoHover {
  background-color: transparent !important;
  border: 1px solid #838383;
}
.borderHover {
  border: 1px solid #838383;
}

.backgroundTransparentApp h4,
.backgroundTransparentApp h1,
.backgroundTransparentApp h5,
.backgroundTransparentApp h2 {
  color: #fff;
  opacity: 0.8;
}
@media (max-width: 739px) {
  body {
    // padding-right: 0px;
  }
}
#root {
  width: 100%;
}
button,
input,
[tabindex] {
  outline: none;
  border: none;
  background-color: transparent;
}

a[href] {
  color: var(--text-color);
  text-decoration: none;
}
li {
  list-style: none;
}
.autoCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
