@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
$active--Color: #31a24c;

.wrapper {
  background-color: var(--backGround--page);
  height: 100%;
  display: flex;
  padding: 15px;
  border-radius: 30px;
  // border: 0px !important;
  transition: 0.5s ease-in-out;
  position: relative;
  .wrapperKeyboard {
    position: absolute;
    z-index: 999;
    bottom: 150px;
    right: 100px;
    background-color: rgba(36, 36, 36, 0.5);
    border: 1px solid #838383;
    opacity: 0.3;
    height: 40px;
    width: 100px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    // border: 5px solid #838383;
    &:hover {
      // border: 5px solid hsl(41, 100%, 51%);
      opacity: 1;
    }
    input {
      width: 80%;
      cursor: pointer;
    }
  }
  &:hover {
    border: 0px !important;
  }
  // padding-left: 100px;
  //   flex: 1;
  @include mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 8px 8px 8px 8px;
    padding-left: 75px;
    position: relative;
  }
  .controlChat {
    // flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
    background-color: var(--backGround--page2--white);
    padding: 15px 0;
    border-radius: 30px;
    overflow: hidden;
    // box-shadow: 5px 20px 50px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.01);
    transition: 0.3s ease-in-out;
    z-index: 3;

    @include mobile {
      // flex: 1;
      position: absolute;
      width: 200px;
      width: 60px;
      padding-bottom: 50px;
      left: 8px;
      bottom: 8px;
      top: 8px;
      right: 8px;
    }

    .wrapperControl {
      padding: 0 15px;
      @include mobile {
        padding: 0 5px;
      }
      @include tablet() {
        padding: 0 14px;
      }
      .createNew {
        display: flex;
        align-items: center;
        // height: 53px;
        padding: 6.5px 0;
        padding-left: 3px;
        border-radius: 30px;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        // &:hover {
        //   border: 1px solid #838383;
        // }
        // margin-bottom: 26px;
        @include mobile {
          padding: 0 0;
          padding-left: 0px;
          justify-content: flex-start;
        }

        .createPlus {
          width: 40px;
          height: 40px;
          background-color: var(--backGround--page);

          // background-color: var(--backGround--page2);
          border-radius: 50%;
          margin-right: 20px;
          margin-left: 7px;
          &:hover {
            // border: 1px solid #838383;
          }
          @include tablet() {
            margin-right: 10px;
          }
          @include mobile {
            width: 35px;
            height: 35px;
            margin-right: 0;
          }
          .creatPlusIcon {
            font-size: 1.7rem;
            color: var(--backGround-App);
          }
        }

        .create__title {
          font-size: 1.6rem;
          white-space: nowrap;
          @include mobile {
            font-size: 1.4rem;
            margin-left: 8px;
            // display: none; // useState
          }
        }
      }
      .wrapperTitle {
        // height: 70px;
        padding: 16.25px 0;
        padding-left: 12px;
        display: flex;
        align-items: center;
        @include mobile {
          padding: 5px 0;
          padding-left: 3px;
        }
        @include tablet {
          padding: 5px 0;
          padding-left: 12px;
        }
        .title__Chat {
          font-size: 2.5rem;
          @include mobile {
            font-size: 2rem;
          }
        }
      }
      .wrapperSearch {
        // height: 40px;
        padding: 13px 0;
        width: 100%;
        position: relative;
        background-color: #f3f6ff;
        background-color: var(--backGround--page);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
        height: 42px;

        @include mobile {
          padding: 10px 0;
          padding-left: 5px;
          height: 38px;
        }

        .searchText {
          width: 80%;
          background-color: transparent;
          height: 100%;
          font-size: 1.4rem;
          padding-left: 16px;
          color: #000;
          &::placeholder {
            color: #8f97a4;
          }
          @include mobile {
            font-size: 1.6rem;
          }
        }
        .textWhite {
          color: #fff;
        }
        .searchIconWrapper {
          position: absolute;
          right: 10px;
          background-color: var(--backGround--page);

          height: 20px;

          &:hover {
            background-color: transparent !important;
            border: 0px;
          }
          @include mobile {
            right: 5px;

            width: 40px;
          }
          .searchIcon {
            // margin-right: 10px;
            color: #8f97a4;
            font-size: 1.6rem;

            @include mobile {
              font-size: 1.5rem;
              // right: 4px;
            }
          }
        }
      }
    }
    .wrapperListUser::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .wrapperListUser {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    .wrapperListUser {
      overflow-y: scroll;
      transition: 0.3s ease-in-out;

      .Loading {
        display: flex;
        justify-content: center;
        // align-items: center;
        height: 53.3px;
      }
      .user {
        display: flex;
        align-items: center;
        height: 53.3px;
        transition: 0.3s ease-in-out;
        padding: 0 15px;
        @include mobile {
          padding: 0 8px;
        }
        @include tablet() {
          padding: 0 8px;
        }
        &:hover {
          background-color: #ccc;
          background-color: rgba(204, 204, 204, 0.5);
        }
        .avata {
          width: 50px;
          height: 50px;
          // @include mobile {
          //   width: 40px;
          //   height: 40px;
          // }
          position: relative;
          @include mobile {
            width: 45px;
            height: 45px;
          }
          .active {
            position: absolute;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: $active--Color;
            bottom: 5px;
            right: 5px;
            @include mobile {
              bottom: 2px;
              right: 2px;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
              border-radius: 50%;
              // width: calc(100% + 2px);
              // height: calc(100% + 2px);
              border: #fff solid 2px;
            }
          }
          .offline {
            position: absolute;
            border-radius: 20px;
            width: 65%;
            height: 14px;
            background-color: #ccfdd7;
            color: $active--Color;
            bottom: 5px;
            right: 3px;
            overflow: hidden;

            p {
              font-size: 0.7rem;
              // letter-spacing: 0.05px;
              // font-weight: 600;
              width: 30px;
              @include mobile {
                width: 30px;
                height: 14px;
                font-size: 5px !important;
                font-weight: 600;
                letter-spacing: 0px;
              }
            }
            @include mobile {
              bottom: 1px;
              right: 2px;
              width: 75%;
            }
            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
              border-radius: 20px;
              // width: calc(100% + 2px);
              // height: calc(100% + 2px);
              border: #fff solid 2px;
              @include mobile {
              }
            }
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            // @include mobile {
            //   width: 35px;
            //   height: 35px;
            // }
          }
        }

        .user__display {
          padding-top: 1px;
          white-space: nowrap;

          // flex: 1;
          @include mobile {
            // display: none; // useState
          }
          .user__name {
            font-size: 1.5rem;
            // font-weight: 400;
            line-height: 1.5rem;

            // @include mobile {
            //   font-size: 1.3rem;
            //   line-height: 1.3rem;
            // }
            @include tablet() {
              max-width: 130px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .user__chatHistory {
            font-size: 1rem;
            color: #8f97a4;
            display: flex;
            // justify-content: space-between;
            .userChatHistory {
              max-width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @include mobile {
                max-width: 200px;
              }
              @include tablet() {
                max-width: 70px;
              }
            }
            p {
              margin-right: 2px;
            }
          }
        }
      }
    }
    .barControlChat {
      position: absolute;
      cursor: pointer;
      bottom: 10px;
      left: 9px;
      // transform: translateX(-50%);
      width: 40px;
      height: 40px;
      background-color: #f3f6ff;
      border-radius: 50%;
      margin-right: 20px;
      display: none;
      @include mobile {
        display: flex;
      }
      .iconBar {
        color: var(--backGround-App);
        font-size: 2rem;
      }
    }
  }
  .rooms {
    flex: 1;
    // background-color: aqua;
    display: flex;
    z-index: 2;
    @include mobile {
      flex: 1;
    }
  }
  .ModalInfoChat {
    // flex: 1;
    display: flex;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.01);
    @include mobile {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 8px 8px 8px 8px;
      // padding-left: 75px;

      // width: calc(100% - 90px);
      position: absolute;
      top: 0;
      // right: 0px;
      left: 70px;
      bottom: 0;
      z-index: 4;
      // margin-left: calc(100% - 30px);
    }
    // display: none;
    // background-color: var(--backGround--page);
  }
}
.createGroup {
  padding: 16px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  .nameGroup {
    height: 30px;
    .lableName {
      padding-right: 20px;
      font-size: 1.6rem;
    }
    .inputName {
      height: 100%;
      border: 2px solid #dce0e3;
      border-radius: 5px;
      width: 60%;
      padding-left: 10px;
      font-size: 1.6rem;
      flex: 1;
    }
    .successful:focus {
      border: solid 2px rgb(178, 247, 161);
    }
    .error {
      border: solid 2px rgb(255, 124, 124) !important;
    }
  }
}
