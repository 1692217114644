$bOrgeTransparent: #fff4e6;
$bPinkTransparent: #fff0f7;
$widthModal: 250px;
$widthModal: 250px;
@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}

.wrapper {
  background-color: var(--backGround--page2--white);
  border-radius: 30px;
  display: flex; //thêm
  flex-direction: column; //thêm
  // padding: 33.33px 20px 0 20px;
  padding-top: 33.33px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @include mobile {
    // width: 100% !important;
    // height: 100%;
    // justify-content: center;
    align-items: center;
    position: relative;
  }

  .closeModal {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 12px;
    left: 10px;
    display: none;
    cursor: pointer;
    .closeModal--icon {
      font-size: 2rem;
      color: var(--backGround-App);
    }
    @include mobile {
      display: flex;
    }
  }
  // overflow: hidden;
  .infoUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    width: $widthModal;
    user-select: none;
    // bug nhảy dòng khi close modal
    // width: $widthModal;
    @include tablet {
      width: 200px;
    }
    .avatar {
      // display: none;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      border: 3.5px solid #fff;
      margin-bottom: 20px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.2);

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        // border: 9px solid rgb(19, 173, 27);
      }
    }
    .nameUser {
      font-size: 2rem;
      text-align: center;
      padding: 0 8px;
    }
  }
  .editNickName {
    padding: 16px;
    max-height: 300px;
    overflow-y: scroll;
    .user {
      @include mobile {
        // flex-direction: row-reverse;
      }
      border-radius: 5px;
      display: flex;
      padding: 8px;
      position: relative;
      transition: 0.2s ease-in-out;
      background-color: rgba(204, 204, 204, 0);
      padding-right: 40px;
      cursor: pointer;

      .avata {
        display: flex;
        // position: relative;
        z-index: 1;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
        &:hover ~ .overlay {
          background-color: rgba(204, 204, 204, 1);
        }
      }
      .user__display {
        z-index: 1;
        padding-left: 10px;
        flex: 1;
        &:hover ~ .overlay {
          background-color: rgba(204, 204, 204, 1);
        }
        input {
          height: 100%;
          border: 2px solid #dce0e3;
          border-radius: 5px;
          width: 100%;
          padding-left: 10px;
          font-size: 1.6rem;
        }
        .user__name {
          font-size: 1.3rem;
          line-height: 1.5rem;
          // white-space: nowrap;
          // font-weight: 300;
        }
        .user__active {
          font-size: 1.3rem;
          color: #8f97a4;
          @include mobile {
            font-size: 1.2rem;
          }
        }
      }
      button {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(204, 204, 204, 0);
        height: 36px;
        width: 36px;
        border-radius: 50%;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: rgba(204, 204, 204, 1);
        }
        .iconPen {
          font-size: 16px;
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        // background-color: #afe530;
        background-color: rgba(204, 204, 204, 0);
        z-index: 0;
        border-radius: 5px;
      }
    }
  }
  .controlRoom {
    padding: 0 18px;
    //bug nhảy dòng khi close modle
    width: $widthModal;
    @include mobile {
      width: 90%;
      min-width: 250px;
    }
    @include tablet {
      width: 200px;
      padding: 0 9px;
    }

    // width: 100%;
    .controlList {
      .content {
        transition: 0.1s ease-in-out;
      }

      .setting {
        background-color: $bOrgeTransparent;
        transition: 0.1s ease-in-out;

        &:hover .settingHover {
          color: #e5a730;
        }
        .icon {
          color: #e5a730;
        }
      }
      .addUser {
        // background-color: $bOrgeTransparent;
        transition: 0.1s ease-in-out;
        &:hover {
          border: 0px;
          color: #f790b8 !important;
        }
        .icon {
          color: #f790b8;
        }
      }
      .leave {
        transition: 0.1s ease-in-out;
        &:hover {
          border: 0px;
          color: #ff0000 !important;
        }
        .icon {
          color: #ff0000;
        }
      }
      .nameGroup {
        background-color: $bOrgeTransparent;
        transition: 0.1s ease-in-out;
        &:hover {
          border: 0px;
          color: #328ee4 !important;
        }
        .icon {
          color: #7dbbf5;
        }
      }
      .nickName {
        background-color: $bOrgeTransparent;
        transition: 0.1s ease-in-out;
        &:hover {
          border: 0px;
          color: #afe530 !important;
        }
        .icon {
          color: #afe530;
        }
      }
      .image {
        background-color: $bPinkTransparent;
        &:hover .imageHover {
          color: #c383a5;
        }
        .icon {
          color: #c383a5;
        }
      }
      .replaceImage {
        &:hover {
          color: #c383a5;
          border: none;
        }
        .icon {
          color: #c383a5;
        }
      }
      .usersGroup {
        background-color: #ebf3bc;
        .userItem {
          display: flex;
          align-items: center;
          position: relative;
          cursor: auto;
          .avata {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 50%;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
          .user__display {
            padding-top: 1px;
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            h5 {
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              @include mobile {
                // max-width: 500px;
                width: 100px;
              }
              @include tablet() {
                width: 100px;
              }
              @media screen and (max-width: 400px) {
                max-width: 100px;
              }
            }

            // flex: 1;
            @include mobile {
              // display: none; // useState
            }
            .position {
              font-size: 1.1rem;
              color: #8f97a4;
            }
            .user__name {
              font-size: 1.4rem;
              line-height: 1.5rem;

              @include tablet() {
                max-width: 130px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }

        &:hover .imageHover {
          color: #8fa320;
        }
        .icon {
          color: #8fa320;
        }
      }

      .controlItem {
        // flex-direction: ;
        border-radius: 20px;
        margin-bottom: 16px;
        padding: 0 8px;
        position: relative;
        cursor: pointer;
        .boxBug {
          display: flex;
          align-items: center;
          padding: 8px 0;
          .iconOpen {
            color: #848589;
            position: absolute;
            right: 8px;
            font-size: 1.2rem;
            // rotate: -90deg;
            transition: 0.2s ease-in-out;
          }
          .wrappIcon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
            }
          }
        }

        .content {
          font-size: 1.4rem;
          font-weight: 600;
          user-select: none;
        }
      }
    }
  }
}
.editNameGroup {
  padding: 16px;
  display: flex;
  flex-direction: column;
  // align-items: center;

  .nameGroup {
    height: 30px;
    .lableName {
      padding-right: 20px;
      font-size: 1.6rem;
    }
    .inputName {
      height: 100%;
      border: 2px solid #dce0e3;
      border-radius: 5px;
      width: 60%;
      padding-left: 10px;
      font-size: 1.6rem;
      flex: 1;
    }
  }
  .addUser {
    height: 40px;
    .inputName {
      height: 100%;
      border: 2px solid #dce0e3;
      border-radius: 5px;
      width: 100%;
      padding-left: 10px;
      font-size: 1.6rem;
      flex: 1;
    }
    .successful:focus {
      border: solid 2px rgb(178, 247, 161);
    }
    .error {
      border: solid 2px rgb(255, 124, 124) !important;
    }
  }
  .successful:focus {
    border: solid 2px rgb(178, 247, 161);
  }
  .error {
    border: solid 2px rgb(255, 124, 124) !important;
  }
}
.addUserGroup {
  height: 450px;
  justify-content: space-between;
  @include mobile {
    height: 350px;
  }
  .selectUser {
    position: relative;
    flex: 1;
    .notification {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      transform: translateX(-50%);
    }
    .listUserSelected {
      padding: 10px 10px;
      height: 100%;
      overflow-x: scroll;
      display: flex;
      &::-webkit-scrollbar {
        // display: none;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(100, 99, 99);
        border-radius: 10px;
      }
      .avata {
        // width: 70px;
        height: 60px;
        align-items: center;
        display: flex;
        flex-direction: column;
        span {
          text-align: center;
          font-size: 1.2rem;
          width: 70px;
        }
        // background-color: #7dbbf5;
        position: relative;
        // @include mobile {
        //   width: 45px;
        //   height: 45px;
        // }
        .close {
          position: absolute;
          top: -4px;
          right: 3px;
          z-index: 1;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: #fff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          cursor: pointer;
          .iconClose {
            font-size: 1.2rem;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    h5 {
      color: #848589;
    }
  }
  .listUserSearch {
    height: 240px;
    @include mobile {
      height: 180px;
    }
    overflow-y: scroll;
    .userItem {
      display: flex;
      align-items: center;
      border-radius: 10px;
      height: 60px;
      position: relative;
      cursor: pointer;
      &:hover {
        background-color: rgba(204, 204, 204, 1);
      }
      .avata {
        width: 50px;
        height: 50px;
        // @include mobile {
        //   width: 40px;
        //   height: 40px;
        // }
        position: relative;
        @include mobile {
          width: 45px;
          height: 45px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          // @include mobile {
          //   width: 35px;
          //   height: 35px;
          // }
        }
      }
      .inputCheck.autoCenter {
        background-color: #ccd;
      }
      .inputCheck {
        position: absolute;
        right: 20px;
        height: 18px;
        width: 18px;
        // background-color: #848589;
        border: solid 2px #848589;
        .iconCheck {
          position: absolute;
          font-size: 1.3rem;
        }
      }
      .user__display {
        padding-top: 1px;
        white-space: nowrap;

        // flex: 1;
        @include mobile {
          // display: none; // useState
        }
        .user__name {
          font-size: 1.5rem;
          // font-weight: 400;
          line-height: 1.5rem;

          // @include mobile {
          //   font-size: 1.3rem;
          //   line-height: 1.3rem;
          // }
          @include tablet() {
            max-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .user__chatHistory {
          font-size: 1rem;
          color: #8f97a4;
          display: flex;
          // justify-content: space-between;
          .userChatHistory {
            max-width: 120px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @include mobile {
              max-width: 200px;
            }
            @include tablet() {
              max-width: 70px;
            }
          }
          p {
            margin-right: 2px;
          }
        }
      }
    }
  }
}
.wrapperNotification {
  display: flex;

  height: 100px;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    font-size: 20px;
  }
}
.childrentControl {
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
}
.userItem,
.childrentControl {
  &:hover {
    border: none;
    background-color: transparent !important;
  }
}
