.wrapper {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  // align-items: center;
  .loader {
    width: 30px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .ball {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: var(--backGround-App);
      animation: bounce 0.2s alternate infinite;
    }
    .ball:nth-child(2) {
      animation-delay: 0.16s;
    }
    .ball:nth-child(3) {
      animation-delay: 0.32s;
    }
    @keyframes bounce {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
        // transform: translateY(-10px) scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}
