@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
$active--Color: #31a24c;

.wrapper {
  padding: 33.3px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  .buttomScrollTop {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--backGround--page2--white);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #d6d6d6;
      transition: 0.2s ease-in-out;
    }
    .icon {
      font-size: 16px;
      color: var(--backGround-App);
    }
  }
  @include mobile {
    padding: 16px 5px 0px 5px;
  }
  .headerBox {
    display: flex;
    justify-content: space-between;

    align-items: center;
    height: 37.68px;
    @include mobile {
      //tesst
      // flex-direction: row-reverse;
      //chinh nó
    }
    .alignCenter {
      display: flex;
      align-items: center;
    }
    .infoUser {
      .user {
        @include mobile {
          // flex-direction: row-reverse;
        }
        display: flex;
        .avata {
          display: flex;
          position: relative;
          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
          }
          .active {
            position: absolute;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: $active--Color;
            bottom: 0;
            right: 0;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
              border-radius: 50%;
              // width: calc(100% + 2px);
              // height: calc(100% + 2px);
              border: #fff solid 2px;
            }
          }
        }
        .user__display {
          margin-left: 10px;
          .user__name {
            font-size: 1.3rem;
            line-height: 1.5rem;
            // white-space: nowrap;
            // font-weight: 300;
          }
          .user__active {
            font-size: 1.3rem;
            color: #8f97a4;
            @include mobile {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .infoRoom-Bar {
      cursor: pointer;
      height: 100%;
      padding-left: 10px;
      @include mobile {
        // display: none;
      }
      .iconMenu {
        color: #8f97a4;
        font-size: 20px;
      }
    }
  }
  .boxMessage {
    overflow: hidden;
    flex: 1;
    display: flex;
    // justify-content: flex-end;
    flex-direction: column-reverse;
    // align-items: center;
    // justify-content: center; // bug xóa ngay
    padding-bottom: 20px;
    overflow-y: scroll;
    padding-right: 2px;
    z-index: 1;
    @include mobile {
      // max-width: 305px;
    }
    @media screen and (max-width: 400px) {
      max-width: calc(100% - 10px);
    }
    .loader {
      background-color: aqua;
    }
    &::-webkit-scrollbar {
      // display: none;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(100, 99, 99);
      border-radius: 10px;
    }
  }

  .controlMessage {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    z-index: 2;
    .WrapperReplyMessage {
      padding: 0 20px;
      padding-bottom: 8px;
      position: relative;

      .replyNameUser {
        display: flex;
        font-size: 1.4rem;
        line-height: 1.5rem;
        span {
          padding-right: 5px;
        }
        .nameUser {
          font-weight: 600;
        }
      }
      .textReply {
        font-size: 1.3rem;
        color: #8f97a4;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include mobile {
          width: 150px;
        }
      }
      .buttonCloseReply {
        position: absolute;
        right: 20px;
        top: 5px;
        // background-color: #31a24c;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;

        cursor: pointer;
        .close-icon {
          font-size: 18px;
        }
      }
    }
    background-color: var(--backGround--page2--white);

    border-radius: 30px;
    padding: 10px 0;
    // padding-bottom: 15px;
    // overflow: hidden;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
    transition: 0.2s ease-in-out;
  }
}
