@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
.zIndex0 {
  z-index: 0 !important;
}
.wrapper {
  width: 100px;
  // height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;

  @include mobile {
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 501;
    // height: 100vh;
  }
  .logoApp {
    user-select: none;
    position: absolute;
    top: 0;
    width: 100%;

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: -10px;
      // background-color: red;
    }
    &:hover .rainy {
      display: flex;
      opacity: 1;
    }
    .rainy {
      position: absolute;
      bottom: -30px;
      display: flex;
      height: 30px;
      justify-content: center;
      background-color: rgba(36, 36, 36, 0.5);
      border-radius: 5px;
      border: 1px solid #838383;
      transition: 0.3s ease-in-out;
      opacity: 0;
      input {
        left: 0;
        width: 85%;
        opacity: 0.7;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }
    }

    transform-style: preserve-3d;
    .wrapperCloud {
      cursor: pointer;
      transition: 0.3s ease-in-out;

      img {
        width: 60px;
        animation: weather 0.3s linear;
      }
    }
    @keyframes weather {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @include mobile {
      // height: 100vh;

      display: none;
    }
    .wrapperEarth {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%; // quỹ đạo quay

      position: relative;
      .earth {
        width: 50px;
        height: 50px;
        background: url("../../../../assets/images/world.png");
        border-radius: 50%;
        background-size: cover;
        background-repeat: repeat-x;
        box-shadow: inset 0 0 10px rgb(0, 0, 0, 1), 0 0 20px #14b5dd;

        animation: animationEarth 20s linear infinite;

        .planet {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          img {
            width: 12%;
          }
        }
        .mars {
          animation: spin 12s linear infinite;
        }
        .jupiter {
          animation: spin-reverse 7s linear infinite;
          img {
            top: -6px;
            position: absolute;
          }
        }
        .mercury {
          animation: spin 3s linear infinite;
        }
      }
      @keyframes animationEarth {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: -300px 0;
        }
      }
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes spin-reverse {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-360deg);
        }
      }
    }

    img {
      width: 60%;
    }
  }

  .navigation {
    flex-direction: column;
    width: 100%;
    position: relative;
    @include mobile {
      width: 100%;
    }

    .line {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 3px;
      height: var(--default-layout-Nav-height);
      background-color: #fff;
      transition: all 0.3s ease;
      animation: toRight 0.8s linear;
      @include mobile {
        display: none;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 3px;
        height: var(--default-layout-Nav-height);
        background-color: #fff;
        transition: all 0.3s ease;
        animation: toRight 0.8s linear;
      }
      // transition: toRightLine 2s ease;
    }
    @keyframes toRight {
      from {
        left: -500px;
      }
      to {
        left: 0px;
      }
    }

    .nav__list {
      width: 100%;
      @include mobile {
        display: flex;
      }
      .mobileDisplay {
        @include mobile {
          display: none;
        }
      }
      .nav__listItem:first-child {
        img {
          width: 50px;
          @include mobile {
            width: 40px;
          }
        }
      }

      .nav__listItem {
        height: var(--default-layout-Nav-height);
        width: 100%;
        img {
          width: 40px;
          @include mobile {
            width: 30px;
          }
        }
        @include mobile {
          height: var(--default-layout-Nav-height--mobile);
        }
        .listItem__Link {
          width: 100%;
          height: 100%;
          z-index: 999;

          .iconNav {
            // display: block;
            z-index: 1;
            color: var(--Icon-Color);
            font-size: 20px;
          }
        }
      }
    }
  }
}
// @media (max-width: 739px) {
//   .wrapper {
//     width: 100%;
//     position: absolute;
//     // background-image: linear-gradient(
//     //   var(--backGround-App),
//     //   var(--backGround-App2)
//     // );
//     // display: none;
//     .logoApp {
//       display: none;
//     }

//     .navigation {
//       width: 100%;
//       .line {
//         display: none;
//         position: absolute;
//         left: 0px;
//         bottom: 0px;
//         width: 3px;
//         height: var(--default-layout-Nav-height);
//         background-color: #fff;
//         transition: all 0.3s ease;
//         animation: toRight 0.8s linear;
//         // transition: toRightLine 2s ease;
//       }
//       @keyframes toRight {
//         from {
//           left: -500px;
//         }
//         to {
//           left: 0px;
//         }
//       }

//       .nav__list {
//         display: flex;
//       }
//     }
//   }
// }
