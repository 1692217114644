@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.WrapperStickerComponent {
  position: absolute;
  bottom: calc(100% + 10px);
  width: 250px;
  left: -20px;
  height: 300px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--backGround--page2--white);
  box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.1);
  // overflow-x: hidden;

  // overflow: hidden;
  // background-color: aqua;
  @include mobile {
    width: 200px;
    height: 250px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -25px;
    // background-color: aqua;
    left: 10px;
    border: 15px solid;
    border-color: var(--backGround--page2--white) transparent transparent
      transparent;
    // box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.1);
  }
  .headerStiker {
    // height: 50px;
    // background-color: aqua;
    border-bottom: solid 1px #ccc;
    width: 100%;

    margin-bottom: 4px;
    // overflow-x: hidden;
    display: flex;
    .listTypeSticker {
      display: flex;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        // display: none;
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(100, 99, 99);
        border-radius: 10px;
      }
      // align-items: center;
      .typeSticker {
        cursor: pointer;
        width: 50px;
        // height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
          height: 80%;
          object-fit: contain;
        }
      }
      .checkedType {
        background-color: rgb(236, 234, 234);
      }
    }
  }
  .continerStiker {
    overflow-y: scroll;
    flex: 1;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    .WrapperSticker {
      width: 25%;
      padding-top: 25%;
      position: relative;
      // margin: 0 5px;
      // margin-bottom: 4px;
      // padding: 2px;
      margin-bottom: 4px;
      .choseSticker {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 2px;
        cursor: pointer;
        // position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          border: 1px solid rgb(241, 231, 231);
        }
      }
    }

    // width: 280 ;
    // background-color: aqua;

    // height: 380px;
  }
}
