@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.controlMessage2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 20px;
}
.openImage {
  height: 22px;
  width: 26px;
  cursor: pointer;

  img {
    width: 20px;
    // margin-left: 20px;
    height: 20px;
    // background-color: aqua;
    opacity: 0.9;
    margin-right: 5px;
  }
}

.openStickers {
  height: 22px;
  width: 22px;
  // cursor: pointer;
  margin-right: 5px;
  position: relative;
  img {
    width: 20px;
    height: 20px;
    opacity: 0.9;
    cursor: pointer;
  }
}
.wrapperTextMessage {
  // background-color: #8f97a4;
  // min-height: 50px;
  display: flex;
  // align-items: center;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .img-container {
    height: 56px;
    width: 100%;

    .imgWaitingSend {
      height: 40px;
      width: 40px;
      position: relative;
      img {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        object-fit: cover;
        z-index: 0;
      }
      .close {
        position: absolute;
        top: -8px;
        right: -8px;
        z-index: 1;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        .iconClose {
          font-size: 1.2rem;
        }
      }
    }
  }

  .inputMessage::-webkit-scrollbar {
    display: none;
  }
  input {
    // flex: 1;
    background-color: var(--backGround--page-white);
    width: 100%;
    color: #000;
    height: 22px;
    &::placeholder {
      font-size: 1.5rem;
    }
    font-size: 1.6rem;
    @include mobile {
      font-size: 1.6rem;
    }
  }
  .textWhite {
    color: #fff;
  }
  .inputMessage {
    //   background-color: transparent;
    height: fit-content;
    // background-color: #8f97a4;
    width: 100%;
    padding-left: 5px;
    // font-size: 1.3rem;
    border: none;
    outline: none;
    max-height: 100px;
    overflow-y: auto;
  }
}

.sendMessage {
  // height: 50px;
  // background-color: aqua;
  // display: flex;
  // align-items: flex-end;

  cursor: pointer;
  .send--icon {
    color: var(--backGround-App);
    font-size: 22px;
    margin-right: 20px;
    margin-left: 15px;
  }
}
