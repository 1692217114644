@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100vh;
  background: url("../../assets/images/login.jpg") top center/ cover no-repeat;
  .continer {
    width: 540px;
    max-width: calc(100vw - 32px);
    background-color: var(--backGround--page);
    border-radius: 8px;
    padding: 30px 8px;
    height: max-content;

    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid #dce0e3;
    color: #fff;
    letter-spacing: 0.15rem;
    .LoginContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logoApp {
        user-select: none;
        // position: absolute;
        top: 0;
        width: 100px;
        transform-style: preserve-3d;
        .wrapperEarth {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%; // quỹ đạo quay

          position: relative;
          .earth {
            width: 50px;
            height: 50px;
            background: url("../../assets/images/world.png");
            border-radius: 50%;
            background-size: cover;
            background-repeat: repeat-x;
            box-shadow: inset 0 0 10px rgb(0, 0, 0, 1), 0 0 20px #74f7e5;
            animation: animationEarth 20s linear infinite;
            .earthSays {
              position: absolute;
              width: 150px;

              border: 2px solid #dce0e3;
              border-radius: 5px;
              padding: 5px;
              background-color: rgb(0, 0, 0, 0.5);
              font-size: 1.3rem;
              font-weight: 300;
              z-index: 1;
              bottom: -28px;
              // bottom: 10px;
              left: 100px;
              // opacity: 0;
              animation: mount 2s ease-in-out;
              @include mobile {
                left: 90px;
                width: 100px;
              }
              @keyframes mount {
                0% {
                  opacity: 0;
                }
                50% {
                  opacity: 1;
                }
              }
              &::after {
                content: "";
                width: 8px;
                height: 8px;
                border: 2px solid #dce0e3;
                position: absolute;
                border-radius: 50%;
                left: -20px;
                @include mobile {
                  left: -18px;
                }
              }
              &::before {
                content: "";
                width: 5px;
                height: 5px;
                border: 2px solid #dce0e3;
                position: absolute;
                border-radius: 50%;
                left: -35px;
                bottom: 38%;
                @include mobile {
                  left: -27px;
                }
              }
            }
            .planet {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              img {
                width: 12%;
              }
            }
            .mars {
              animation: spin 12s linear infinite;
            }
            .jupiter {
              animation: spin-reverse 7s linear infinite;
              img {
                top: -6px;
                position: absolute;
              }
            }
            .mercury {
              animation: spin 3s linear infinite;
            }
          }
          @keyframes animationEarth {
            0% {
              background-position: 0 0;
            }
            100% {
              background-position: -300px 0;
            }
          }
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
          @keyframes spin-reverse {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-360deg);
            }
          }
        }

        img {
          width: 60%;
        }
      }
      .Title {
        font-size: 2.7rem;
        margin-top: 20px;
        text-align: center;
        @include mobile {
          font-size: 2rem;
        }
      }
    }
    .body {
      @include mobile {
        padding-top: 0px;
      }
      padding-top: 15px;
      flex-direction: column;
      .signinButton {
        width: 320px;
        max-width: calc(100% - 10px);

        height: 44px;
        border-radius: 44px;
        border: 2px solid #dce0e3;

        font-weight: 600;
        margin-top: 14px;
        position: relative;
        overflow: hidden;
        input {
          background-color: rgba(0, 0, 0, 0.5);
          width: 100%;
          height: 100%;
          color: #fff;
          font-size: 1.6rem;
          padding-left: 50px;
        }

        // &:hover {
        //   background-color: rgba(167, 167, 167, 0.3);
        // }

        .icon {
          position: absolute;
          left: 0;
          margin-left: 20px;
        }
        .conectTitle {
        }
      }
      .logInApp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 320px;
        max-width: calc(100% - 10px);
        padding: 0 10px;
        margin-top: 16px;
        .singInGoogle {
          border: 1px solid #dce0e3;
          border-radius: 50%;
          height: 30px;
          transition: 0.1s ease-in-out;
          width: 30px;
          cursor: pointer;
          &:hover {
            background-color: rgba(167, 167, 167, 0.3);
          }
        }
        .logIn {
          width: 140px;
          height: 40px;
          cursor: pointer;
          transition: 0.1s ease-in-out;
          margin-top: 0px;
          font-size: 1.5rem;
          &:hover {
            background-color: rgba(167, 167, 167, 0.3);
          }
        }
      }

      .account {
        width: 320px;
        max-width: calc(100% - 10px);
        font-size: 1.3rem;
        padding: 0 10px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        p {
          cursor: pointer;
          &:hover {
            color: #ccc;
          }
        }

        // text-align: right;
      }
    }
  }
}
