@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
$color-special: #967acc;
$color-special-PlaceHoder: #a9a9b8;
.wrapper {
  .title {
    padding: 0 10px;
    margin-top: 20px;
    width: 320px;
    max-width: calc(100% - 10px);
  }
  .signinButton {
    width: 320px;
    max-width: calc(100% - 10px);
    color: #fff;
    height: 44px;
    border-radius: 44px;
    // border: 2px solid #dce0e3;

    font-weight: 600;
    margin-top: 14px;
    position: relative;
    overflow: hidden;
    input {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 1.6rem;
      padding-left: 50px;
    }

    // &:hover {
    //   background-color: rgba(167, 167, 167, 0.3);
    // }

    .icon {
      position: absolute;
      left: 0;
      margin-left: 20px;
    }
    .conectTitle {
    }
  }
  .logInApp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 320px;
    max-width: calc(100% - 10px);
    padding: 0 10px;
    margin-top: 16px;
    .singInGoogle {
      border: 1px solid #dce0e3;
      border-radius: 50%;
      height: 30px;
      transition: 0.1s ease-in-out;
      width: 30px;
      cursor: pointer;
      &:hover {
        background-color: rgba(167, 167, 167, 0.3);
      }
    }
    .logIn {
      width: 140px;
      height: 40px;
      cursor: pointer;
      transition: 0.1s ease-in-out;
      margin-top: 0px;
      font-size: 1.5rem;
      border: 2px solid #dce0e3;

      &:hover {
        background-color: rgba(167, 167, 167, 0.3);
      }
    }
  }

  .account {
    width: 320px;
    max-width: calc(100% - 10px);
    font-size: 1.3rem;
    padding: 0 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    p {
      cursor: pointer;
      &:hover {
        color: #ccc;
      }
    }

    // text-align: right;
  }
}
