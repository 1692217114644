@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper {
  padding: 40px 100px;
  display: flex;
  height: 100%;
  //bug ở đây height 100%
  // align-items: center;
  // justify-content: center;
  overflow-y: scroll;
  // height: 100%;
  @include tablet {
    padding: 40px 20px;
  }
  @include mobile {
    padding: 10px;
    flex-direction: column;
  }
  .myProfileInfo {
    flex: 1;
    background-color: var(--backGround--page);
    height: 300px;
    padding-top: 35px;
    padding-bottom: 40px;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);
    @include mobile {
      flex: 1;
      height: 250px;
      padding-top: 23.3px;
      padding-bottom: 20.6px;
      margin-right: 0px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
    .myProfileInfo--avata {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      @include mobile {
        width: 86.66px;
        height: 86.66px;
        margin-bottom: 13.33px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .myProfileInfo--contact {
      text-align: center;
      font-size: 1.2rem;
      color: #7d7d7d;
      @include mobile {
        font-size: 1rem;
      }
      .myProfileInfo--Name {
        font-size: 1.6rem;
        margin-bottom: 5px;
        color: var(--text-color);
        @include mobile {
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
      }
      .myProfileInfo--Email {
        margin-bottom: 5px;
        @include mobile {
          margin-bottom: 2px;
        }
      }
      .myProfileInfo--Nation {
      }
    }
  }
  .myProfileContent {
    flex: 1.8;
    // background-color: transparent !important;
    border-radius: 20px;
    @include mobile {
      border-radius: 10px;
    }
    .backgroundSpecial {
      background-color: #e1edf5;
    }
    .colorRedOr {
      color: #e97233;
    }
    .myProfileContent__Property {
      border-radius: 10px;
      display: flex;
      padding: 0 25px;
      font-size: 1.2rem;
      //   flex-direction: column;
      align-items: center;
      height: 50px;
      //   justify-content: center;
      @include mobile {
        padding: 0 15px;
      }
    }
    .PropertyName {
      flex: 1;
      color: #7d7d7d;
    }
    .Property--content {
      flex: 1;
      font-weight: 600;
    }
    .logout {
      // float: left;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px 20px 20px 0px;
      .buttonLogout {
        background-color: var(--backGround-App);
        // opacity: 0.9;
        height: 40px;
        width: 140px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;
        color: #fff;
        position: relative;
        @include mobile {
          height: 35px;
          width: 120px;
          border-radius: 5px;
          font-size: 1.4rem;
        }

        &:hover {
          background-color: #8c35fd;
        }
        .buttonContent {
        }
        .iconLogout {
          position: absolute;
          left: 15px;
          font-size: 1.6rem;
          @include mobile {
            display: none;
            font-size: 1.5rem;
          }
          // color: #fff;
        }
      }
    }
  }
}
