@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}

// display: none;
.wrapperTippy {
  position: absolute;
  right: 5px;

  .controlerAdmin {
    cursor: pointer;
    .icon {
      font-size: 2rem;
      color: #848589;
    }
  }
}
.controlList {
  background-color: var(--backGround--page2--white);
  width: 180px;
  height: 100px;
  padding: 5px 5px;
  top: -10px;
  left: -10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.1);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -25px;
    right: 5px;
    border: 15px solid;
    border-color: var(--backGround--page2--white) transparent transparent
      transparent;
  }
  .control {
    flex: 1;
    // background-color: aqua;
    // position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
    // height: 40px;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    .icon {
      // position: absolute;
    }
    .nameControl {
      // text-align: left; \
      font-weight: 600;
      padding-left: 20px;
      font-size: 1.4rem;
    }
  }
}
