.wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper {
  padding: 40px 100px;
  display: flex;
  overflow-y: scroll;

  .myProfileInfo {
    flex: 1;
    background-color: var(--backGround--page);
    height: 300px;
    padding-top: 35px;
    padding-bottom: 40px;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);

    .myProfileInfo--avata {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .myProfileInfo--contact {
      text-align: center;
      font-size: 1.2rem;
      color: #7d7d7d;
      .myProfileInfo--Name {
        font-size: 1.6rem;
        margin-bottom: 5px;
        color: var(--text-color);
      }
      .myProfileInfo--Email {
        margin-bottom: 5px;
      }
      .myProfileInfo--Nation {
      }
    }
  }
  .myProfileContent {
    flex: 1.8;
    // background-color: transparent !important;
    border-radius: 20px;
    .backgroundSpecial {
      background-color: #e1edf5;
    }
    .colorRedOr {
      color: #e97233;
    }
    .myProfileContent__Property {
      border-radius: 10px;
      display: flex;
      padding: 0 25px;
      font-size: 1.2rem;
      //   flex-direction: column;
      align-items: center;
      height: 50px;
      //   justify-content: center;
    }
    .PropertyName {
      flex: 1;
      color: #7d7d7d;
    }
    .Property--content {
      flex: 1;
      font-weight: 600;
    }
  }
}
