@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}

// display: none;

.wrapperEmoji {
  // position: absolute;
  // height: 30px;
  // background-color: aqua;
  position: relative;

  .listEmoji {
    position: absolute;
    display: flex;
    // right: 0;
    // right: 0;
    top: -1px;
    background-color: var(--backGround--page);
    height: 18px;
    align-items: center;
    padding: 0 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: pointer;
    .countEmoji {
      font-size: 13px;
      line-height: 18px;
      padding: 0 2px;
      padding-top: 2px;
      // font-size: ;
    }
    .emoji {
      width: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 14px;
        height: 14px;
        // animation: jump 0.5s ease-in-out;
        border: none;
      }
      @keyframes jump {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
  .listEmoji.friend {
    // left: calc(100% - 30px);
  }
  .listEmoji.userLogin {
    right: 0;
  }
}
