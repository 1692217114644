@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}

// display: none;
.buttonControlText {
  // background-color: aqua;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  cursor: pointer;

  &:hover {
    background-color: #8f97a4;
  }
  &:hover .icon {
    color: #696969;
  }
  .icon {
    color: #8f97a4;
    font-size: 1.4rem;
  }
}

.boxRemove {
  width: 100px !important;
  border-radius: 10px;
  // border: 1px solid #838383;
  // background-color: #fff;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
  border: 4px solid var(--backGround--page);
  // padding: 5px;
  overflow: hidden;
  .removeMessage {
    border-radius: 5px;
    height: 30px;
    background-color: var(--backGround--page);
    cursor: pointer;
    &:hover {
      background-color: #dbdbdb;
    }
    span {
      font-size: 1.5rem;
      font-weight: 500;
      color: #000;
      text-shadow: 0 0 rgb(129, 129, 129);
    }
  }
}
.boxListIcon {
  width: 230px;
  height: 36px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
  background-color: var(--backGround--page) !important;
  border-radius: 40px;
  .listIcon {
    width: 100%;
    display: flex;
    // background-color: aqua;
    justify-content: space-between;
    .icon {
      width: 24px !important;
      height: 24px !important;

      cursor: pointer;
      &:hover img {
        transform: scale(1.2);
        // background-color: transparent !important;
      }

      img {
        width: 24px !important;
        height: 24px !important;
        max-width: 100%;
        transition: 0.2s ease-in-out;
        border: none;
      }
    }
  }
}
