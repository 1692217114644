@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.selected {
  color: var(--backGround-App);
}
.wrapperEmojiMessage {
  padding: 16px;
  padding-top: 10px;
  .listTypeEmoji {
    display: flex;
    height: 50px;
    position: relative;

    @include mobile {
      height: 50px;
    }
    align-items: center;
    .line {
      position: absolute;
      width: 14.28%;
      height: 5px;
      background-color: var(--backGround-App);
      border-radius: 5px;
      bottom: 0;
      transition: 0.15s linear;
      @include mobile {
        width: 20%;
      }
    }
    .emoji {
      width: 14.28%;
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      @include mobile {
        width: 20%;
        font-size: 14px;
        text-align: center;
      }
      img {
        // padding-bottom: 2px;
        width: 22px;
        height: 22px;
        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
      .countEmoji {
        padding-left: 3px;
        // color: #8f97a4;
        // font-size;
      }
    }
    .line {
    }
  }
  .listEmoji {
    height: 240px;
    overflow-y: scroll;
    @include mobile {
      height: 120px;
    }
  }
}
