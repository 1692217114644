.wrapper {
  background-color: var(--backGround--page);
  height: 100%;
  padding: 15px;
  border-radius: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .profile {
    .profile__Navigate {
      height: 80px;
      .navigate__List {
        position: relative;

        height: 100%;
        display: flex;
        .navigate__item {
          flex: 1;
          .navigate__item-link {
            border-top-right-radius: 30px;
            border-top-left-radius: 30px;
            font-size: 1.5rem;
            // display: block;
            height: 100%;

            .iconUser {
              color: #b7df96;
              margin-right: 10px;
              font-size: 2rem;
            }
            .iconUserEdit {
              color: #74c8f4;
              margin-right: 10px;
              font-size: 2rem;
            }
            .iconMyMusic {
              color: #e4a254;
              margin-right: 10px;
              font-size: 2rem;
            }
          }
        }
        .line {
          position: absolute;
          bottom: 0;
          height: 5px;
          //   width: 50%;
          background-color: var(--backGround-App2);
          opacity: 0.6;
          border-radius: 20px;
          transition: all 0.2s ease;
        }
      }
    }
  }
}
@media (max-width: 739px) {
  .wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0 8px var(--default-layout--mobile--bottom) 1px;

    // padding-bottom: 5px;
    .profile {
      .profile__Navigate {
        height: 70px;
        .navigate__List {
          .navigate__item {
            flex: 1;
            .navigate__item-link {
              .iconUser {
              }
              .iconUserEdit {
              }
              .iconMyMusic {
              }
            }
          }
          .line {
          }
        }
      }
    }
  }
}
