@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.wrapper {
  padding: 40px 100px;
  display: flex;
  overflow-y: scroll;
  @include tablet {
    padding: 40px 20px;
  }
  @include mobile {
    padding: 10px;
    flex-direction: column;
  }
  .myProfileInfo {
    flex: 1;
    background-color: var(--backGround--page);
    height: 300px;
    padding-top: 35px;
    padding-bottom: 40px;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    @include mobile {
      flex: 1;
      height: 250px;
      padding-top: 23.3px;
      padding-bottom: 20.6px;
      margin-right: 0px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
    .myProfileInfo--avata {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      // overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      @include mobile {
        width: 86.66px;
        height: 86.66px;
        margin-bottom: 13.33px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        // cursor: pointer;
      }
      .camera {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        width: 100%;
        border-radius: 50%;
        opacity: 0;
        transition: 0.2s ease-in-out;
        &:hover {
          opacity: 1;
        }

        .camereIcon {
          font-size: 40px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .myProfileInfo--contact {
      text-align: center;
      font-size: 1.2rem;
      color: #7d7d7d;
      @include mobile {
        font-size: 1rem;
      }
      .myProfileInfo--Name {
        font-size: 1.6rem;
        margin-bottom: 5px;
        color: var(--text-color);
        @include mobile {
          font-size: 1.4rem;
          margin-bottom: 5px;
        }
      }
      .myProfileInfo--Email {
        margin-bottom: 5px;
        @include mobile {
          margin-bottom: 2px;
        }
      }
      // .myProfileInfo--Nation {
      // }
    }
  }
  .borderInput {
    border: solid 2px #ccc;
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    transition: 0.3s ease;
  }
  .successful:focus {
    border: solid 2px rgb(178, 247, 161);
  }
  .error {
    border-radius: 5px;
    height: 30px;
    padding-left: 10px;
    border: solid 2px rgb(255, 124, 124) !important;
  }
  .myProfileContent {
    flex: 1.8;

    // background-color: transparent !important;
    border-radius: 20px;
    @include mobile {
      border-radius: 10px;
    }
    .backgroundSpecial {
      background-color: #e1edf5;
    }
    .colorRedOr {
      color: #e97233;
    }
    .myProfileContent__Property {
      border-radius: 10px;
      display: flex;
      padding: 0 25px;
      font-size: 1.2rem;
      //   flex-direction: column;
      align-items: center;
      height: 50px;

      justify-content: space-between;
      @include mobile {
        padding: 0 15px;
      }
    }
    .PropertyName {
      // flex: 1;
      min-width: 70px;
      color: #7d7d7d;
    }
    .Property--content {
      // flex: 1;
      height: 30px;
      font-weight: 600;
      min-width: 350px;
      font-size: 1.6rem;
      @include tablet {
        min-width: 200px;
      }
      @include mobile {
        min-width: 150px;
      }
    }
    .replace {
      width: 35px;
      height: 35px;
      // background-color: #8c35fd;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
      .iconReplace {
        color: var(--backGround-App);
        font-size: 1.6rem;
      }
    }
    .noScale {
      &:hover {
        transform: scale(1) !important;
      }
    }
  }
  .logout {
    // float: left;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    padding: 20px 20px 20px 0px;
    position: relative;
    height: 60px;
    .opacity05 {
      opacity: 0.5;
    }
    .opacity1 {
      opacity: 1;
    }
    .poiter {
      cursor: pointer;
    }

    .buttonLogout {
      position: absolute;
      // right: 50px;
      bottom: 0;
      background-color: var(--backGround-App);
      // opacity: 0.9;
      height: 40px;
      width: 140px;
      border-radius: 6px;
      transition: all 0.3s ease;
      color: #fff;
      transition: 0.2s ease;
      @include mobile {
        height: 35px;
        width: 120px;
        border-radius: 5px;
        font-size: 1.4rem;
        right: 50px !important;
      }

      &:hover {
        // color: rgb(63, 62, 62);
        background-color: #8c35fd;
      }
      // .buttonContent {
      // }
      .iconLogout {
        position: absolute;
        left: 15px;
        font-size: 1.6rem;
        @include mobile {
          display: none;
          font-size: 1.5rem;
        }
        // color: #fff;
      }
    }
  }
}
