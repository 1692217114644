.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    width: 120px;
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;

    .ball {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: rgb(185, 140, 174);
      // background-color: var(--backGround-App);
      animation: bounce 0.4s alternate infinite;
    }
    .ball:nth-child(2) {
      animation-delay: 0.16s;
    }
    .ball:nth-child(3) {
      animation-delay: 0.32s;
    }
    @keyframes bounce {
      from {
        transform: scale(1.25);
        opacity: 0.5;
      }
      to {
        transform: translateY(-30px) scaleX(1);
        opacity: 1;
      }
    }
  }
}
