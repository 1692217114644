@mixin mobile {
  @media screen and (max-width: 739px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 740px) and (max-width: 1023px) {
    @content;
  }
}
.pointer {
  cursor: pointer;
}
.mgBt_20px {
  margin-bottom: 20px;
}
.mgtop_20px {
  margin-top: 20px;
}
.mgBtEmoji {
  margin-bottom: 15px;
}

.borderRadiusLeft-8 {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.borderRadiusRight-8 {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.borderRadius {
  border-radius: 20px !important;
}
.borderRadius_leftBt-8 {
  border-bottom-left-radius: 8px !important;
}
.borderRadius_leftTop-8 {
  border-top-left-radius: 8px !important;
}
.borderRadius_rightBt-8 {
  border-bottom-right-radius: 8px !important;
}
.borderRadius_rightBt-20 {
  border-bottom-right-radius: 20px !important;
}
.borderRadius_leftBt-20 {
  border-bottom-left-radius: 20px !important;
}
.borderRadius_rightTop-8 {
  border-top-right-radius: 8px !important;
}
.onlyMess {
  border-radius: 20px !important;
}
.styleSticker {
  width: 100px !important;
  height: 100px !important;
}
.notification {
  // background-color: #000;
  text-align: center;
  // width: 80%;
  .textNotification {
    font-size: 1.1rem;
    width: 80%;
    color: #a1a1a1;
  }
}
.message__chat {
  display: flex;
  align-items: flex-end;
  position: relative;
  .nameUserSend {
    position: absolute;
    top: -15px;
    left: 54px;
    font-size: 1rem;
    color: #a1a1a1;
  }
  // margin-top: auto;
  // margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0px;
  }
  &:hover .boxText .optionTextMessage {
    display: flex;
  }

  .focus {
    display: flex !important;
  }
  .boxTextDeletedMessage {
    background-color: var(--backGround--page);
    padding: 6px 12px;
    // padding-bottom: 8px;
    border-radius: 20px;
    max-width: 60%;
    margin-top: 5px;
    position: relative;
    border: 1px solid #838383;
    width: fit-content;
    .deletedMessage {
      position: relative;
      // background-color:;
      span {
        color: #838383;
      }
    }
  }
  .boxText {
    background-color: var(--backGround-App);
    padding: 15px;
    padding-bottom: 6px;
    padding-top: 6px;
    border-radius: 20px;
    // border-radius: 8px;
    // border-bottom-left-radius: 20px;
    // border-top-left-radius: 20px;
    max-width: 60%;
    margin-top: 2px;
    position: relative;
    .textMessage {
      &:hover ~ .textTime {
        display: flex;
      }
    }
    .textTime {
      font-size: 1rem;
      color: #000;
      position: absolute;
      background-color: rgba($color: #808080, $alpha: 0.8);
      top: -18px;
      // transform: translateX(50%);
      padding: 2px 10px;
      border-radius: 5px;
      display: none;
      animation: hide 0.5s ease-in-out;
    }
    @keyframes hide {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .optionTextMessage {
      z-index: 1;
      position: absolute;
      right: 100%;
      // width: 50px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      justify-content: space-around;
      display: none;
    }

    .textMessage {
      font-size: 1.4rem;
      color: #fff;
      word-break: break-word;
    }
  }
  .avatar {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin-right: 10px;
    width: 30px;
    height: 30px;

    img {
      border-radius: 50%;

      height: 100%;
      width: 100%;
    }
  }
}
//bug///////////////////////---------------------------
.flexColumn {
  flex-direction: column !important; //bug reverse
}

.messageChat {
  display: flex;
  // flex-direction: column; //bug reverse
  flex: 1;
  // background-color: aqua;
  // margin-bottom: 20px;
  &:hover .imgMessage .imgMessageOptions {
    display: flex;
  }
  .imgMessage {
    max-width: 60%;
    position: relative;

    img {
      border: 1px solid rgba($color: #808080, $alpha: 0.1);
    }
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;

    .imgMessageOptions {
      // position: absolute;
      display: flex;
      // display: none;
      flex-direction: row-reverse;
    }

    @include mobile {
      max-height: 150px;
      // max-width: 40%;
    }
    @include tablet {
      // max-width: 40%;
    }
    .imgMessageSending {
      border-radius: 10px;
      object-fit: cover;
      max-width: 300px !important;
      @include mobile {
        // max-height: 150px;
        max-width: 150px !important;
      }
      @media screen and (max-width: 400px) {
        // max-width: 200px;
      }
      @include tablet {
        max-width: 200px !important;
      }
      // cursor: pointer;
    }
    // fixbug
    // .imgMessageSending {
    //   width: max-content;
    //   max-width: 60%;
    //   max-height: 200px;
    //   // min-height: 150px !important; //bug
    //   border-radius: 10px;
    //   object-fit: cover;
    //   cursor: pointer;
    //   @include mobile {
    //     max-height: 100px;
    //   }
    //   @include tablet {
    //     max-width: 100%;
    //   }
    // }
    .stickerMes {
      min-height: 100px !important; //bug
    }
  }

  .boxText {
    background-color: var(--backGround-App);
    background-color: #ccc;
    // padding: 15px;
    // padding-bottom: 6px;
    // padding-top: 6px;
    border-radius: 20px;
    // border-radius: 8px;
    // border-bottom-right-radius: 20px;
    // border-top-right-radius: 20px;
    max-width: 60%;
    margin-top: 2px;
    width: fit-content;
    position: relative;
    .optionTextMessage {
      z-index: 1;
      left: 100%;
      width: 65px;
      margin: 0 5px;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
    .textMessage {
      font-size: 1.4rem;
      color: var(--text-color);
      word-break: break-word;
    }
    .textTime {
      font-size: 1rem;
      // display: none;
    }
  }
  .avatar {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }
}
.imageReply {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.user {
  flex-direction: column;

  .WrapperReplyMessage {
    position: relative;
    padding-top: 5px;
    text-align: right;
    margin-top: 2px;
    max-width: 60%;

    .reply {
      position: absolute;
      background-color: rgba($color: #808080, $alpha: 0.5);
      width: 100%;
      height: calc(100% + 5px);
      bottom: -5px;
      border-radius: 20px;
      // z-index: 0;
    }

    .textReply {
      // padding-top: 20px;
      padding-left: 15px;
      font-size: 1.4rem;
      // padding: ;
      padding-right: 15px;
      // width: 60%;
      // overflow: hidden;
      word-break: break-word;
      // white-space: nowrap;
      // text-overflow: ellipsis;
    }
  }
  &:hover .imgMessage .imgMessageOptions {
    display: flex;
  }
  .imgMessage {
    max-width: 60%;
    max-height: 200px;
    width: max-content;
    // overflow: hidden;
    border: 1px solid rgba($color: #808080, $alpha: 0.1);
    border-radius: 10px;
    margin-top: 3px;
    position: relative;
    // display: flex;
    // flex-direction: row-reverse;
    .emojiImage {
      position: absolute;
      background-color: #000;
      left: calc(100% - 10px);
      bottom: 2px;
    }
    // display: flex;

    img {
      border-radius: 10px;
      object-fit: cover;
      max-width: 300px !important;
      @include mobile {
        // max-height: 150px;
        max-width: 150px !important;
      }
      @media screen and (max-width: 400px) {
        // max-width: 200px;
      }
      @include tablet {
        max-width: 200px !important;
      }
    }
    .imgMessageOptions {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      display: none;
    }
    @include mobile {
      max-height: 150px;
    }
    @include tablet {
      // max-width: 40%;
    }
  }

  .textTime {
    right: calc(100% + 10px);
    text-align: right;
  }
}
.friend {
  .WrapperReplyMessage {
    position: relative;
    padding-top: 5px;
    text-align: left;
    margin-top: 2px;
    width: fit-content;
    max-width: 60%;
    .reply {
      position: absolute;
      background-color: rgba($color: #808080, $alpha: 0.5);
      width: 100%;
      height: calc(100% + 5px);
      bottom: -5px;
      border-radius: 20px;

      // z-index: 0;
    }

    .textReply {
      // padding-top: 20px;
      word-break: break-word;
      padding-left: 15px;
      font-size: 1.4rem;
      // padding: ;
      padding-right: 15px;
    }
  }
  .imgMessage {
    // background-color: #000;
    margin-top: 3px;
    z-index: 0;
    position: relative;
    .emojiImage {
      position: absolute;
      // right: ;
      bottom: 2px;
      left: 13px;
      // height: 100px;
      width: 35px;
    }
  }
  .textTime {
    left: calc(100% + 10px);
  }
}
.buttonControlText {
  // background-color: aqua;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  cursor: pointer;

  &:hover {
    background-color: #8f97a4;
  }
  &:hover .icon {
    color: #696969;
  }
  .icon {
    color: #8f97a4;
    font-size: 1.4rem;
  }
}

.boxRemove {
  width: 100px !important;
  border-radius: 10px;
  // border: 1px solid #838383;
  // background-color: #fff;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
  border: 4px solid var(--backGround--page);
  // padding: 5px;
  overflow: hidden;
  .removeMessage {
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
    &:hover {
      background-color: #000;
    }
    span {
      font-size: 1.5rem;
      font-weight: 500;
      color: #000;
      text-shadow: 0 0 rgb(129, 129, 129);
    }
  }
}
.boxListIcon {
  width: 250px;
  height: 48px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 30%);
  background-color: var(--backGround--page) !important;
  border-radius: 40px;
  .listIcon {
    width: 100%;
    display: flex;
    // background-color: aqua;
    justify-content: space-between;
    .icon {
      width: 28px !important;
      height: 28px !important;
      cursor: pointer;
      &:hover img {
        transform: scale(1.2);
        // background-color: transparent !important;
      }

      img {
        width: 28px;
        height: 28px;
        max-width: 100%;
        transition: 0.2s ease-in-out;
      }
    }
  }
}
